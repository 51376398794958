<template>
  <div class="skeleton" :style="_styles" />
</template>

<script>
export default {
  name: 'Skeleton',
  props: {
    width: { type: [String, Number], default: '100%' },
    height: { type: [String, Number], default: 10 }
  },
  computed: {
    _styles() {
      return {
        width: this._width,
        height: this._height
      }
    },
    _width() {
      return this.isNumeric(this.width) ? this.width + '%' : this.width
    },
    _height() {
      return this.isNumeric(this.height) ? this.height + 'px' : this.height
    }
  },
  methods: {
    isNumeric(value) {
      return /^\d+$/.test(value)
    }
  }
}
</script>
