<template>
  <navbar>
    <div class="navbar-brand" @click="$router.go(-1)">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6666 9.66667V12.3333H5.66663L13 19.6667L11.1066 21.56L0.546631 11L11.1066 0.440002L13 2.33334L5.66663 9.66667H21.6666Z"
          fill="#F2F2F2"
        />
      </svg>
    </div>
    <h6 class="title-navbar">
      Campaign
    </h6>
  </navbar>
  <list-campaign />
</template>

<script>
import Navbar from '../components/TransactionComponent/NavbarTransaction.vue'
import ListCampaign from '../components/Campaign/ListCampaign.vue'

export default {
  components: { Navbar, ListCampaign }
}
</script>
