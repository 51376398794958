<template>
  <div class="container container__category">
    <div class="category__wrapper">
      <div class="list__category-wrapper" v-for="n in count" :key="n">
        <div class="information__category">
          <Skeleton width="200px" height="22" />
          <div class="progress progress__category" style="margin-top:0.5rem;">
            <Skeleton width="200px" height="8" />
          </div>
          <Skeleton width="160px" height="25" />
          <Skeleton width="133px" height="30px" style="margin-top:0.5rem;"/>
        </div>
        <div class="tumbnail__category-gallery">
          <div class="cover__category">
            <Skeleton width="113px" height="113" style="border-radius: 6px; position:absolute; top:-5px; right:5px;"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from '../Skeleton/Skeleton.vue'
export default {
  components: { Skeleton },
  props: {
    count: { type: Number, default: 1 }
  }
}
</script>
